import { PaginatedResponse } from '../../../interface/responses/PaginatedResponse';
import { Session } from '../../../interface/Session';
import api from '../../index';

export const fetchSessions = async (page: number, perPage: number) => {
  const { data } = await api.get<PaginatedResponse<Session>>(
    `/organization-admin-api/sessions`,
    {
      params: {
        page,
        perPage,
      },
    }
  );

  return data;
};

//!!Depreceted
// export const cancelSession = async (sessionId: number) => {
//   const { data } = await api.delete(
//     `/organization-admin-api/sessions/${sessionId}`
//   );

//   return data;
// };

export const cancelSession = async (sessionId: number) => {
  const { data } = await api.post(`/organization-admin-api/sessions/${sessionId}/cancel`);

  return data;
};

export const finishSession = async (sessionId: number) => {
  const { data } = await api.post(`/organization-admin-api/sessions/${sessionId}/finish`);

  return data;
};

export default { fetchSessions, cancelSession, finishSession };
