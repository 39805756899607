import { Employee } from '../../../interface/Employee';

import api from '../../index';

export const fetchEmployees = async (): Promise<Employee[]> => {
  const { data } = await api.get<Employee[]>(`/organization-admin-api/employees`);

  return data;
};

export const fetchParkingGroupEmployees = async (parkingGroupId: number) => {
  const { data } = await api.get<Employee[]>(
    `/organization-admin-api/parking-groups/${parkingGroupId}/employees`
  );

  return data;
};

export const assignParkingSpotToEmployee = async (
  parkingSpotId: number,
  employeeId: number
) => {
  const { data } = await api.post('/organization-admin-api/sessions', {
    parkingSpotId,
    employeeId,
  });

  return data;
};

export const assignLicensePlateNumberToEmployee = async (
  employeeId: number,
  licensePlateNumber: string
) => {
  const { data } = await api.patch(
    `/organization-admin-api/employees/${employeeId}/license-plate-number`,
    {
      licensePlateNumber,
    }
  );

  return data;
};

// TODO: waiting for API
export const detachParkingSpotFromEmployee = async (parkingSpotId: number) => {
  const { data } = await api.delete('');

  return data;
};

export default {
  fetchEmployees,
  fetchParkingGroupEmployees,
  assignParkingSpotToEmployee,
  detachParkingSpotFromEmployee,
  assignLicensePlateNumberToEmployee,
};
