import React from 'react';

const AutomationsIcon = () => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.7'
        y='2.7'
        width='10.6'
        height='8.6'
        rx='1.3'
        stroke='#1D1F1F'
        strokeWidth='1.4'
      />
      <circle cx='3' cy='5' r='0.75' fill='#1D1F1F' stroke='#1D1F1F' strokeWidth='0.5' />
      <circle cx='9' cy='5' r='0.75' fill='#1D1F1F' stroke='#1D1F1F' strokeWidth='0.5' />
      <path
        d='M3 7V8.8C3 8.91046 3.08954 9 3.2 9H8.8C8.91046 9 9 8.91046 9 8.8V7'
        stroke='#1D1F1F'
        strokeWidth='1.5'
      />
      <rect x='2' width='1.5' height='3' fill='#1D1F1F' />
      <rect x='8.5' width='1.5' height='3' fill='#1D1F1F' />
    </svg>
  );
};

export default AutomationsIcon;
