import React from "react";

const VisitorsIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="5.25" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M2 9.52779C3.06151 8.57771 4.46329 8 6 8C7.53671 8 8.93849 8.57771 10 9.52779"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <circle
        cx="6"
        cy="5"
        r="1.25"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default VisitorsIcon;
