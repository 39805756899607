import { useState } from 'react';

interface Arguments {
  initialPerPage?: number;
  initialPage?: number;
}

export const usePagination = (config?: Arguments) => {
  const { initialPage = 1, initialPerPage = 10 } = config ?? {};

  const [perPage, setPerPage] = useState(initialPerPage);
  const [page, setPage] = useState(initialPage);
  const [total, setTotal] = useState(0);

  return {
    page,
    perPage,
    total,
    setPage,
    setPerPage,
    setTotal,
  };
};
