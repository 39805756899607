import React, { createContext } from 'react';

import ApplicationStore from '../store/ApplicationStore';
import { IApplicationStoreContext } from '../interface/ApplicationStore';

export const ApplicationStoreContext = createContext<IApplicationStoreContext>(null);

const ApplicationStoreProvider = ({ children }) => (
  <ApplicationStoreContext.Provider value={ApplicationStore}>
    {children}
  </ApplicationStoreContext.Provider>
);

export default ApplicationStoreProvider;
