import { useCallback, useEffect, useState } from "react";

interface Config<T> {
  onSuccess?: (response: T) => void;
}

export function useFetch<T>(fetch: () => Promise<T>, config?: Config<T>) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const [data, setData] = useState<T | null>(null);

  const innerFetch = useCallback(async () => {
    setIsLoading(true);
    setIsSuccess(false);
    setIsError(false);

    try {
      const data = await fetch();

      setData(data);
      setIsSuccess(true);

      config.onSuccess?.(data);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [config, fetch]);

  useEffect(() => {
    innerFetch();
  }, [innerFetch]);

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch: innerFetch,
  };
}
