import { PaginatedResponse } from '../../../interface/responses/PaginatedResponse';
import { Spot } from '../../../interface/Spot';
import api from '../../index';

export const fetchOrganizationParkingSpots = async (perPage: number, page: number) => {
  const { data } = await api.get<PaginatedResponse<Spot>>(
    `/organization-admin-api/parking-spots`,
    {
      params: {
        perPage,
        page,
      },
    }
  );

  return data;
};

export const fetchParkingGroupSpots = async (
  parkingGroupId: number,
  perPage: number,
  page: number
) => {
  const { data } = await api.get<PaginatedResponse<Spot>>(
    `/organization-admin-api/parking-groups/${parkingGroupId}/parking-spots`,
    {
      params: {
        perPage,
        page,
      },
    }
  );

  return data;
};

export const assignParkingSpotToParkingGroup = async (
  parkingGroupId: number,
  parkingSpotId: number
) => {
  const { data } = await api.post(
    `/organization-admin-api/parking-groups/${parkingGroupId}/parking-spots`,
    {
      parkingSpotId,
    }
  );

  return data;
};

export const detachParkingSpotFromParkingGroup = async (
  parkingGroupId: number,
  parkingSpotId: number
) => {
  const { data } = await api.delete(
    `/organization-admin-api/parking-groups/${parkingGroupId}/parking-spots/${parkingSpotId}`
  );

  return data;
};

export const addMapToParkingSpot = async (parkingSpotId: number, mapFile: File) => {
  const formData = new FormData();
  formData.append('mapFile', mapFile);

  const { data } = await api.post(
    `/organization-admin-api/parking-spots/${parkingSpotId}/map`,
    formData
  );

  return data;
};

export const removeMapFromParkingSpot = async (parkingSpotId: number) => {
  const { data } = await api.delete(
    `/organization-admin-api/parking-spots/${parkingSpotId}/map`
  );

  return data;
};

export default {
  fetchOrganizationParkingSpots,
  fetchParkingGroupSpots,
  assignParkingSpotToParkingGroup,
  detachParkingSpotFromParkingGroup,
  addMapToParkingSpot,
  removeMapFromParkingSpot,
};
