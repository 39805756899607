import React from 'react';
import { Tag } from '@signal-os/ui-kit';
import { useTranslation } from 'react-i18next';
import styles from './ParkingSpot.module.scss';

interface Props {
  spot: string;
  level: string;
}

const ParkingSpot: React.FC<Props> = ({ spot, level }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <div className={styles.container}>
        <div className={styles.tagContainer}>
          <Tag
            size='medium'
            type='default'
            style={{
              marginRight: 0,
            }}
          >
            {spot}
          </Tag>
        </div>
        <span className={styles.level}>
          {t('component:parkingSpot.level', { level })}
        </span>
      </div>
    </div>
  );
};

export default ParkingSpot;
