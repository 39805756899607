import React, { useState, useEffect } from 'react';
import { Modal, Button, Table, Pagination, Avatar } from '@signal-os/ui-kit';
import { useTranslation } from 'react-i18next';
import { Employee } from '../../../interface/Employee';
// import useDebounce from "../../../hooks/useDebunce";
import organizationEmployeesApi from '../../../api/repositories/organization/employees';
import { useCallback } from 'react';
import { usePagination } from '../../../hooks/usePagination';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  groupId: number;
  handleAssignToGroup: (employeeId: number) => Promise<void> | void;
  loadingStates: { [key: number]: boolean };
}

const MODAL_HEADER_HEIGHT = '55px';
const MODAL_HEIGHT = '655px';

const AssignEmployeeModal: React.FC<Props> = ({
  isOpen,
  onClose,
  groupId,
  handleAssignToGroup,
  loadingStates,
}) => {
  const { t } = useTranslation();

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const {
    page: currentPage,
    perPage,
    total: totalItems,
    setPage: setCurrentPage,
    setPerPage,
    setTotal: setTotalItems,
  } = usePagination();

  // const [searchValue, setSearchValue] = useState("");
  // const search = useDebounce(searchValue, 500);

  const fetchOrganizationEmployees = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await organizationEmployeesApi.fetchEmployees();
      setTotalItems(data.length);
      setEmployees(data);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  }, [setTotalItems]);

  useEffect(() => {
    fetchOrganizationEmployees();
  }, [groupId, isOpen, perPage, currentPage, fetchOrganizationEmployees]);

  const columns = [
    {
      key: 'user-email',
      title: t('page:groupDetails.columns.email'),
      dataIndex: 'email',
      render: (row, email) => {
        return <Avatar title={email} titleStyle={{ fontSize: 13, margin: 0 }} />;
      },
    },
    {
      key: 'user-action',
      title: t('page:groupDetails.columns.action'),
      dataIndex: 'id',
      width: '160px',
      render: (row: Employee, employeeId: number) => {
        const employeeIsAvailableToAssign = !row.parkingGroupId;
        const isAssignedToCurrentGroup = row.parkingGroupId === groupId;

        return employeeIsAvailableToAssign ? (
          <Button
            type='primary'
            size='small'
            inverted
            onClick={async (e) => {
              e.stopPropagation();

              await handleAssignToGroup(employeeId);
              await fetchOrganizationEmployees();
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0 10px',
              whiteSpace: 'nowrap',
              border: 'none',
              boxShadow: 'none',
            }}
            loading={loadingStates[employeeId]}
          >
            {t('page:groupDetails.buttons.addToGroup')}
          </Button>
        ) : isAssignedToCurrentGroup ? (
          <span>In current group</span>
        ) : null;
      },
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      withClose
      style={{
        width: '80%',
        height: MODAL_HEIGHT,
      }}
    >
      <Modal.Header>
        <span style={{ fontSize: 16 }}>{t('component:assignEmployeeModal.title')}</span>
      </Modal.Header>
      <Modal.Body
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: `calc(${MODAL_HEIGHT} - ${MODAL_HEADER_HEIGHT})`,
        }}
      >
        {/* <div
          style={{
            marginBottom: 8,
          }}
        >
          <Filters
            filters={[]}
            onSearchValueChange={(value) => setSearchValue(value)}
          />
        </div> */}

        <Table
          columns={columns}
          dataSource={employees.filter((spot) => spot.parkingGroupId !== groupId)}
          loading={isLoading}
          maxHeight={`calc(${MODAL_HEIGHT} - ${MODAL_HEADER_HEIGHT} - 50px - 40px)`}
        />
        <Pagination
          handleChangePage={(page) => setCurrentPage(page)}
          handleChangePerPage={(perPage) => setPerPage(perPage)}
          page={currentPage}
          perPage={perPage}
          perPageSwitch={false}
          totalItems={totalItems}
          itemsFromLabel={t('component:pagination.itemsFrom')}
          itemsLabel={t('component:pagination.items')}
          pageLabel={t('component:pagination.page')}
          perPageLabel={t('component:pagination.perPage')}
          perPageOptions={[5, 10, 15]}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AssignEmployeeModal;
