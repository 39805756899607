import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter as Router } from "react-router-dom";

// SignalOS - org_qQrO7F74Xq7yEczl

function bootstrap(organization_id: string, theme: string = 'dark'){
  ReactDOM.render(
    <React.StrictMode>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        audience={process.env.REACT_APP_ORGANIZATION_API_URL}
        organization={organization_id}
      >
        <Router>
          <App />
        </Router>
      </Auth0Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

if(process.env.REACT_APP_AUTH0_ORG_ID){
  // If ORG_ID is provided in env variables, boot app with it (only for development purpose)
  console.debug('Static bootstrap', process.env.REACT_APP_AUTH0_ORG_ID)
  bootstrap(process.env.REACT_APP_AUTH0_ORG_ID)
} else {
  // If not, send handshake message to Platform Panel 
  window.top.postMessage({
    type: 'signal-os-handshake'
  },'*');
  // and wait for handshake response with organization_id and boot app with it
  window.addEventListener('message', event => {
    if(event.data && event.data.type && event.data.type === 'signal-os-handshake' && event.data.success){
      console.debug('Organization id: ', event.data.organization_id)
      bootstrap(event.data.organization_id);
    }
  })
}