import { useCallback, useEffect, useState } from 'react';
import { Button, Card, PageHeader, Pagination, Table, Tag } from '@signal-os/ui-kit';
import { useTranslation } from 'react-i18next';

import Container from '../../../components/molecules/Container/Container';

import api from '../../../api/repositories/organization/parking-spots';
import { Spot } from '../../../interface/Spot';
import { usePagination } from '../../../hooks/usePagination';
import TableActionDropdown from '../../../components/molecules/tables/TableActionDropdown/TableActionDropdown';
import AddMapToParkingSpotModal from '../../../components/organisms/Modals/AddMapToParkingSpotModal';

const SiteParkingSpots = () => {
  const { t } = useTranslation();

  const [parkingSpots, setParkingSpots] = useState<Spot[]>([]);
  const [parkingSpot, setParkingSpot] = useState<Spot>();
  const [isAddImageModalOpen, setIsAddImageModalOpen] = useState<boolean>(false);

  const {
    perPage,
    setPerPage,
    page: currentPage,
    setPage: setCurrentPage,
    total: totalItems,
    setTotal: setTotalItems,
  } = usePagination();

  const [isLoading, setIsLoading] = useState(false);

  const loadParkingSpots = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await api.fetchOrganizationParkingSpots(perPage, currentPage);
      setParkingSpots(data.items);
      setTotalItems(data.total);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, perPage, setTotalItems]);

  useEffect(() => {
    loadParkingSpots();
  }, [loadParkingSpots]);

  const columns = [
    {
      key: 'parking-spot-name',
      title: t('page:parkingSpots.columns.name'),
      dataIndex: 'name',
    },
    {
      key: 'parking-spot-level',
      title: t('page:parkingSpots.columns.level'),
      dataIndex: 'level',
    },
    {
      key: 'parkin-spot-map',
      title: t('page:parkingSpots.columns.isMapAdded'),
      dataIndex: 'mapUrl',
      render: (row) => {
        return (
          <div style={{ display: 'inline-block' }}>
            <Tag size='medium' type={row.mapUrl ? 'success' : 'danger'}>
              {row.mapUrl ? t('common:tags.yes') : t('common:tags.no')}
            </Tag>
          </div>
        );
      },
    },
    {
      key: 'parking-spot-qr-code',
      title: t('page:parkingSpots.columns.qrCode'),
      dataIndex: 'token',
    },
    {
      key: 'action',
      title: t('page:parkingSpots.columns.action'),
      dataIndex: 'action',
      width: '100px',
      render: (row) => {
        return (
          <TableActionDropdown>
            <Button
              style={{
                display: 'flex',
                flex: 1,
                marginBottom: 4,
                padding: '0 10px',
                whiteSpace: 'nowrap',
                border: 'none',
                boxShadow: 'none',
              }}
              type='primary'
              size='small'
              inverted
              onClick={() => {
                setParkingSpot(row);
                return setIsAddImageModalOpen(true);
              }}
            >
              {t('page:parkingSpots.columns.addMap')}
            </Button>
          </TableActionDropdown>
        );
      },
    },
  ];

  return (
    <Container>
      <PageHeader title='Parking spots' />
      <Card>
        <Table columns={columns} dataSource={parkingSpots} loading={isLoading} />
        <Pagination
          handleChangePage={(page) => setCurrentPage(page)}
          handleChangePerPage={(perPage) => setPerPage(perPage)}
          page={currentPage}
          perPage={perPage}
          perPageSwitch={false}
          totalItems={totalItems}
          itemsFromLabel={t('component:pagination.itemsFrom')}
          itemsLabel={t('component:pagination.items')}
          pageLabel={t('component:pagination.page')}
          perPageLabel={t('component:pagination.perPage')}
          perPageOptions={[5, 10, 15]}
        />
      </Card>
      <AddMapToParkingSpotModal
        isOpen={isAddImageModalOpen}
        onClose={() => {
          setIsAddImageModalOpen(false);
          setParkingSpot(null);
          loadParkingSpots();
        }}
        parkingSpot={parkingSpot}
        setParkingSpot={setParkingSpot}
      />
    </Container>
  );
};

export default SiteParkingSpots;
