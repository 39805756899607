import React from "react";

export default function ParkingIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 11.3L3 11.3C1.72975 11.3 0.700001 10.2703 0.700001 9L0.7 6.48683C0.7 6.23963 0.739854 5.99403 0.818029 5.75951L1.98031 2.27268C2.29337 1.33349 3.17229 0.7 4.16228 0.7L7.83772 0.699999C8.82771 0.699999 9.70663 1.33349 10.0197 2.27268L11.182 5.75951C11.2601 5.99403 11.3 6.23962 11.3 6.48683L11.3 9C11.3 10.2703 10.2703 11.3 9 11.3Z"
        stroke="currentColor"
        strokeWidth="1.4"
      />
      <circle cx="3" cy="8" r="1" fill="currentColor" />
      <circle cx="9" cy="8" r="1" fill="currentColor" />
      <line
        x1="1"
        y1="5.3"
        x2="11"
        y2="5.3"
        stroke="currentColor"
        strokeWidth="1.4"
      />
      <line
        x1="3.5"
        y1="10.5"
        x2="8.5"
        y2="10.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
}
