import React, { CSSProperties } from "react";

interface Props {
  style?: CSSProperties;
}

const Container: React.FC<Props> = ({ children, style }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          ...style,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Container;
