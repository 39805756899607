import React from 'react';
import { Button, Card, Input, PageHeader, Select } from '@signal-os/ui-kit';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from '../../../components/molecules/Container/Container';
import groupsApi from '../../../api/repositories/organization/groups';
import styles from '../../../styles/form.module.scss';

const AddGroup = () => {
  const { t } = useTranslation();
  const routerHistory = useHistory();

  const validationSchema = yup.object().shape({
    name: yup.string().required(t('validation:nameRequired')),
    type: yup.string().required(t('validation:typeRequired')),
    spotAssignmentType: yup.mixed().when('type', {
      is: 'flex',
      then: (field) => field.required(t('validation:maxDaysAheadOfReservationRequired')),
    }),
    maxSessionSlots: yup.mixed().when('type', {
      is: 'flex',
      then: (field) => field.required(t('validation:maxDaysAheadOfReservationRequired')),
    }),
    maxReservations: yup.mixed().when('type', {
      is: 'flex',
      then: (field) => field.required(t('validation:maxReservation')),
    }),
    // HID for now because it has to be 24h fro now
    // slotResolutionMilliseconds: yup.mixed().when("type", {
    //   is: "flex",
    //   then: (field) =>
    //     field.required(t("validation:slotResolutionMillisecondsRequired")),
    // }),
  });

  return (
    <Container>
      <PageHeader
        title={t('page:addGroup.title')}
        backIcon
        onBack={() => routerHistory.goBack()}
      />
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          name: '',
          type: 'flex',
          // hardcoded because API only support flex and automatic place assignment
          spotAssignmentType: 'automatic',
          maxSessionSlots: '',
          slotResolutionMilliseconds: '24',
          maxReservations: '',
        }}
        onSubmit={async (values, { setErrors }) => {
          try {
            await groupsApi.addParkingGroup(
              values.name,
              values.type,
              values.type === 'static' ? 'automatic' : values.spotAssignmentType,
              values.type === 'static' ? null : parseInt(values.maxSessionSlots),
              values.type === 'static'
                ? null
                : parseInt(values.slotResolutionMilliseconds) * 3600000,
              values.type === 'static' ? 1 : parseInt(values.maxReservations)
            );

            routerHistory.push('/organization/groups');
          } catch (error) {
            console.log(error);
          }
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <Card
            style={{
              padding: '40px',
              display: 'flex',
              flexDirection: 'row',
              flexBasis: '50%',
            }}
          >
            <div
              style={{
                marginRight: 24,
                flex: 0.8,
              }}
            >
              <h3 className={styles.formTitle}>{t('page:addGroup.title')}</h3>
              <p className={styles.formDescription}>{t('page:addGroup.description')}</p>
            </div>

            <div
              style={{
                flex: 1,
              }}
            >
              <div style={{ marginBottom: 32, width: '100%' }}>
                <Field name='name'>
                  {({
                    field: { value, name },
                    form: { setFieldValue },
                    meta: { error, touched },
                  }) => (
                    <Input
                      name={name}
                      value={value}
                      onChange={(_, newValue) => {
                        setFieldValue(name, newValue);
                      }}
                      label={t('component:createGroupModal.labels.name')}
                      error={error && touched ? error : undefined}
                    />
                  )}
                </Field>
                <p className={styles.fieldDescription}>
                  {t('component:createGroupModal.labels.nameDescription')}
                </p>
              </div>
              <div style={{ marginBottom: 32, width: '100%' }}>
                <Field name='type'>
                  {({
                    field: { value, name },
                    form: { setFieldValue },
                    meta: { error, touched },
                  }) => (
                    <Select
                      label={t('component:createGroupModal.labels.typeOfPlaces')}
                      items={[
                        {
                          label: t('page:groups.parkingTypes.flexible'),
                          value: 'flex',
                        },
                        {
                          label: t('page:groups.parkingTypes.permanent'),
                          value: 'static',
                        },
                      ]}
                      onSelect={(value) => {
                        setFieldValue(name, value);
                      }}
                      selectedItem={value}
                      placeholder={t('component:createGroupModal.labels.typeOfPlaces')}
                      error={error && touched ? error : undefined}
                    />
                  )}
                </Field>
                <p className={styles.fieldDescription}>
                  {t('component:createGroupModal.labels.typeOfPlacesDescription')}
                </p>
              </div>
              <div style={{ marginBottom: 32, width: '100%' }}>
                <Field name='maxSessionSlots'>
                  {({
                    field: { value, name },
                    form: { setFieldValue },
                    meta: { error, touched },
                  }) => (
                    <Input
                      name={name}
                      value={value}
                      type='number'
                      onChange={(_, newValue) => {
                        setFieldValue(name, newValue);
                      }}
                      label={t('component:createGroupModal.labels.maxDays')}
                      error={error && touched ? error : undefined}
                      disabled={values.type === 'static'}
                    />
                  )}
                </Field>
                <p className={styles.fieldDescription}>
                  {t('component:createGroupModal.labels.maxDaysDescription')}
                </p>
              </div>
              <div style={{ marginBottom: 32, width: '100%' }}>
                <Field name='maxReservations'>
                  {({
                    field: { value, name },
                    form: { setFieldValue },
                    meta: { error, touched },
                  }) => (
                    <Input
                      name={name}
                      value={value}
                      type='number'
                      onChange={(_, newValue) => {
                        setFieldValue(name, newValue);
                      }}
                      label={t('component:createGroupModal.labels.maxReservations')}
                      error={error && touched ? error : undefined}
                      disabled={values.type === 'static'}
                    />
                  )}
                </Field>
                <p className={styles.fieldDescription}>
                  {t('component:createGroupModal.labels.maxDaysDescription')}
                </p>
              </div>
              {/* // HID for now because it has to be 24h fro now */}
              {/* <div style={{ marginBottom: 32, width: "100%" }}>
                <Field name="slotResolutionMilliseconds">
                  {({
                    field: { value, name },
                    form: { setFieldValue },
                    meta: { error, touched },
                  }) => (
                    <Input
                      name={name}
                      value={value}
                      type="number"
                      onChange={(_, newValue) => {
                        setFieldValue(name, newValue);
                      }}
                      label={t(
                        "component:createGroupModal.labels.reservationInterval"
                      )}
                      error={error && touched ? error : undefined}
                      disabled={values.type === "static"}
                    />
                  )}
                </Field>
                <p className={styles.fieldDescription}>
                  {t(
                    "component:createGroupModal.labels.reservationIntervalDescription"
                  )}
                </p>
              </div> */}
              <div style={{ marginBottom: 32, width: '100%' }}>
                <Field name='spotAssignmentType'>
                  {({
                    field: { value, name },
                    form: { setFieldValue },
                    meta: { error, touched },
                  }) => (
                    <Select
                      label={t('component:createGroupModal.labels.typeOfSpotAssignment')}
                      items={[
                        {
                          label: t('page:groups.spotAssignmentType.automatic'),
                          value: 'automatic',
                        },
                        {
                          label: t('page:groups.spotAssignmentType.manual'),
                          value: 'manual',
                        },
                      ]}
                      onSelect={(value) => {
                        setFieldValue(name, value);
                      }}
                      selectedItem={value}
                      placeholder={t(
                        'component:createGroupModal.labels.typeOfSpotAssignment'
                      )}
                      multiple
                      error={error && touched ? error : undefined}
                      disabled={values.type === 'static'}
                    />
                  )}
                </Field>
                <p className={styles.fieldDescription}>
                  {t('component:createGroupModal.labels.typeOfSpotAssignmentDescription')}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  size='medium'
                  type='primary'
                  onClick={() => handleSubmit()}
                  loading={isSubmitting}
                >
                  {t('common:buttons.submit')}
                </Button>
              </div>
            </div>
          </Card>
        )}
      </Formik>
    </Container>
  );
};

export default AddGroup;
