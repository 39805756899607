import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./locales/en";
import pl from "./locales/pl";

const DEFAULT_LANG = "en";

const ns = [...Object.getOwnPropertyNames(en)];

export const resources = {
  en,
  pl,
} as const;

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: DEFAULT_LANG,
    ns,
    resources,
    detection: {
      order: ["localStorage"],

      lookupLocalStorage: "i18nextLng",

      caches: ["localStorage"],
    },
  });

export default i18next;
