import React from "react";

const DashboardIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="11.25"
        y="11.25"
        width="10.5"
        height="10.5"
        rx="2.25"
        transform="rotate(180 11.25 11.25)"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <line
        x1="1"
        y1="8.25"
        x2="11"
        y2="8.25"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <line
        x1="1"
        y1="4.25"
        x2="11"
        y2="4.25"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default DashboardIcon;
