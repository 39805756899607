import { useState, useEffect } from 'react';
import { Avatar, Button, Card, PageHeader, Table, Tag } from '@signal-os/ui-kit';
import { useTranslation } from 'react-i18next';

import Container from '../../../components/molecules/Container/Container';
import employeesApi from '../../../api/repositories/organization/employees';
import groupApi from '../../../api/repositories/organization/groups';
import { Employee } from '../../../interface/Employee';
import TableActionDropdown from '../../../components/molecules/tables/TableActionDropdown/TableActionDropdown';
import { useCallback } from 'react';
import ConfirmModal from '../../../components/organisms/Modals/ConfirmModal';
import AssignLicensePlateToEmployeeModal from '../../../components/organisms/Modals/AssignLicensePlateToEmployeeModal';

const Employees = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBanModalOpen, setIsBanModalOpen] = useState<boolean>(false);
  const [employeeToBan, setEmployeeToBan] = useState<Employee | null>(null);
  const [employees, setEmployees] = useState<Employee[]>([]);

  const [isAssignPlateNumberModalOpen, setIsAssignPlateNumberModalOpen] =
    useState<boolean>(false);
  const [employee, setEmployee] = useState<Employee | null>(null);

  const { t } = useTranslation();

  const fetchEmployees = useCallback(async () => {
    try {
      setIsLoading(true);

      const fetchedEmployees = await employeesApi.fetchEmployees();

      setEmployees(fetchedEmployees);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  const usersColumns = [
    {
      key: 'email',
      title: t('page:employees.columns.email'),
      dataIndex: 'email',
      render: (row, email) => {
        return <Avatar titleStyle={{ fontSize: 13, marginBottom: 0 }} title={email} />;
      },
    },
    {
      key: 'plates',
      title: t('page:employees.columns.licensePlate'),
      dataIndex: 'bannedAt',
      render: (row: Employee) => {
        return (
          <div style={{ display: 'inline-block' }}>
            <Tag size='medium' type={row.vehicleLicensePlate ? 'success' : 'info'}>
              {row.vehicleLicensePlate
                ? row.vehicleLicensePlate
                : t('page:employees.table.notAssign')}
            </Tag>
          </div>
        );
      },
    },
    {
      key: 'ban',
      title: t('page:employees.columns.isBanned'),
      dataIndex: 'bannedAt',
      render: (row: Employee) => {
        return (
          <div style={{ display: 'inline-block' }}>
            <Tag size='medium' type={row.bannedAt ? 'danger' : 'info'}>
              {row.bannedAt ? 'Banned' : t('page:employees.table.notBanned')}
            </Tag>
          </div>
        );
      },
    },
    {
      key: 'action',
      title: t('page:employees.columns.action'),
      dataIndex: 'id',
      width: '100px',
      render: (row: Employee, id: number) => {
        return (
          <TableActionDropdown>
            <Button
              style={{
                display: 'flex',
                flex: 1,
                padding: '0 10px',
                whiteSpace: 'nowrap',
                border: 'none',
                boxShadow: 'none',
              }}
              type='primary'
              inverted
              size='small'
              onClick={() => {
                setEmployee(row);
                setIsAssignPlateNumberModalOpen(true);
              }}
            >
              {t('page:employees.table.licensePlates')}
            </Button>
            <Button
              type={row.bannedAt ? 'primary' : 'danger'}
              size='small'
              inverted
              onClick={(e) => {
                e.stopPropagation();

                setEmployeeToBan(row);

                setIsBanModalOpen(true);
              }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                flex: 1,
                padding: '0 10px',
                whiteSpace: 'nowrap',
                border: 'none',
                boxShadow: 'none',
              }}
            >
              {row.bannedAt
                ? t('page:employees.table.unBan')
                : t('page:employees.table.ban')}
            </Button>
          </TableActionDropdown>
        );
      },
    },
  ];

  return (
    <Container>
      <PageHeader title={t('page:employees.title')} />
      <Card>
        <Table loading={isLoading} columns={usersColumns} dataSource={employees} />
      </Card>
      <ConfirmModal
        isOpen={isBanModalOpen}
        onClose={() => {
          setIsBanModalOpen(false);
          setEmployeeToBan(null);
        }}
        question={
          employeeToBan?.bannedAt
            ? t('component:confirmModal.doYouWantToUnBan')
            : t('component:confirmModal.doYouWantToBan')
        }
        onConfirm={async () => {
          try {
            if (employeeToBan?.bannedAt) {
              await groupApi.unbanEmployee(employeeToBan?.id);

              await fetchEmployees();
            } else {
              await groupApi.banEmployee(employeeToBan?.id);

              await fetchEmployees();
            }
          } catch (error) {
            console.log(error);
          }
        }}
      />
      <AssignLicensePlateToEmployeeModal
        employee={employee}
        isOpen={isAssignPlateNumberModalOpen}
        onClose={() => {
          setIsAssignPlateNumberModalOpen(false);
          setEmployee(null);
        }}
        fetchEmployees={fetchEmployees}
      />
    </Container>
  );
};

export default Employees;
