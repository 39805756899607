import React from 'react';
import { Modal, Button, Input } from '@signal-os/ui-kit';
import { useTranslation } from 'react-i18next';
import { Employee } from '../../../interface/Employee';
import { Field, Formik } from 'formik';
import employeesApi from '../../../api/repositories/organization/employees';
import { toast } from 'react-toastify';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  employee: Employee;
  fetchEmployees: () => void;
}

const AssignLicensePlateToEmployeeModal: React.FC<Props> = ({
  isOpen,
  onClose,
  employee,
  fetchEmployees,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      withClose
      style={{
        width: 400,
        height: 240,
      }}
    >
      <Formik
        initialValues={{
          licensePlateNumber: employee?.vehicleLicensePlate,
        }}
        onSubmit={async (values, { setErrors }) => {
          try {
            const responseFromAdding =
              await employeesApi.assignLicensePlateNumberToEmployee(
                employee.id,
                values.licensePlateNumber
              );
            fetchEmployees();
            onClose();
            toast.success(t('[age:employees.modal.assignLicensePlateNumber'));
          } catch (error) {
            setErrors({
              licensePlateNumber: t('validation:thereWasAnError'),
            });
          }
        }}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <>
            <Modal.Header headerStyle={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{
                  fontSize: 16,
                }}
              >
                {t('page:employees.modal.assignLicensePlateNumber')}
              </span>
            </Modal.Header>
            <Modal.Body
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Field name='licensePlateNumber'>
                {({
                  field: { value, name },
                  form: { setFieldValue },
                  meta: { error, touched },
                }) => (
                  <Input
                    name={name}
                    value={value}
                    onChange={(_, newValue) => {
                      setFieldValue(name, newValue);
                    }}
                    label={t('page:employees.modal.licensePlateNumber')}
                    error={error && touched ? error : undefined}
                  />
                )}
              </Field>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  onClose();
                }}
                size='medium'
                type='danger'
                inverted
              >
                {t('common:buttons.cancel')}
              </Button>

              <Button
                size='medium'
                type='primary'
                onClick={() => handleSubmit()}
                loading={isSubmitting}
              >
                {t('common:buttons.submit')}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default AssignLicensePlateToEmployeeModal;
