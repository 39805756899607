import common from "./common.json";
import page from "./page.json";
import component from "./component.json";
import validation from "./validation.json";

export default {
  common,
  page,
  component,
  validation,
};
