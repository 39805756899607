import AuthStore from "./AuthStore";

class ApplicationStore {
  initStores = () => {
    this.auth.init();
  };

  auth = new AuthStore();
}

export default new ApplicationStore();
