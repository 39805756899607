import React from "react";
import {
  Modal,
  Button,
  Table,
  // Filters
} from "@signal-os/ui-kit";
import { useTranslation } from "react-i18next";
// import useDebounce from "../../../hooks/useDebunce";
import { Employee } from "../../../interface/Employee";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleAssignParkingSpotToEmployee: (
    employeeId: number
  ) => Promise<void> | void;
  employees: Employee[];
  loadingStates: { [key: number]: boolean };
}

const MODAL_HEADER_HEIGHT = "55px";
const MODAL_HEIGHT = "655px";

const AssignParkingSpotToEmployeeModal: React.FC<Props> = ({
  isOpen,
  onClose,
  handleAssignParkingSpotToEmployee,
  loadingStates,
  employees,
}) => {
  const { t } = useTranslation();

  // const [searchValue, setSearchValue] = useState("");
  // const search = useDebounce(searchValue, 500);

  // useEffect(() => {
  //   console.log({ search });
  // }, [search]);

  const columns = [
    {
      key: "employee-modal-email",
      title: t("page:groupDetails.columns.email"),
      dataIndex: "email",
    },
    {
      key: "employee-modal-action",
      title: t("page:groupDetails.columns.action"),
      dataIndex: "id",
      width: "100px",
      render: (row: Employee, employeeId: number) => {
        console.log({ row });

        return (
          <Button
            type="primary"
            size="small"
            inverted
            onClick={async (e) => {
              e.stopPropagation();

              await handleAssignParkingSpotToEmployee(employeeId);

              onClose();
            }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0 10px",
              whiteSpace: "nowrap",
              border: "none",
              boxShadow: "none",
            }}
            loading={loadingStates[employeeId]}
          >
            {t("page:groupDetails.buttons.assignSpot")}
          </Button>
        );
      },
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      withClose
      style={{
        width: "80%",
        height: MODAL_HEIGHT,
      }}
    >
      <Modal.Header>
        <span style={{ fontSize: 16 }}>
          {t("component:assignParkingSpotToEmployeeModal.title")}
        </span>
      </Modal.Header>
      <Modal.Body
        style={{
          display: "flex",
          flexDirection: "column",
          height: `calc(${MODAL_HEIGHT} - ${MODAL_HEADER_HEIGHT})`,
        }}
      >
        {/* <div
          style={{
            marginBottom: 8,
          }}
        >
          <Filters
            filters={[]}
            onSearchValueChange={(value) => setSearchValue(value)}
          />
        </div> */}
        <Table
          columns={columns}
          dataSource={employees}
          maxHeight={`calc(${MODAL_HEIGHT} - ${MODAL_HEADER_HEIGHT} - 50px - 40px)`}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AssignParkingSpotToEmployeeModal;
