import React from "react";

const Plus = () => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.09249 8H4.90751V4.90752H8V3.09249H4.90751V0H3.09249V3.09249H0V4.90752H3.09249V8Z"
        fill="white"
      />
    </svg>
  );
};

export default Plus;
