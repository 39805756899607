import React, { useState, useEffect, useCallback } from 'react';
import {
  Avatar,
  Button,
  ButtonIcon,
  Card,
  PageHeader,
  Pagination,
  Table,
  Tabs,
  // Select,
} from '@signal-os/ui-kit';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Container from '../../../components/molecules/Container/Container';
import AssignEmployeeModal from '../../../components/organisms/Modals/AssignEmployeeModal';
import employeesApi from '../../../api/repositories/organization/employees';
import groupsApi from '../../../api/repositories/organization/groups';
import parkingSpotsApi from '../../../api/repositories/organization/parking-spots';
import sessionsApi from '../../../api/repositories/organization/sessions';
import { Employee } from '../../../interface/Employee';
import { Spot } from '../../../interface/Spot';
import Plus from '../../../assets/icons/Plus';
import AssignParkingSpotModal from '../../../components/organisms/Modals/AssignParkingSpotModal';
import TableActionDropdown from '../../../components/molecules/tables/TableActionDropdown/TableActionDropdown';
// import EditGroupForm from "../../../components/molecules/forms/EditGroupForm";
import AssignParkingSpotToEmployeeModal from '../../../components/organisms/Modals/AssignParkingSpotToEmployeeModal';

import styles from '../../../styles/group-edit.module.scss';
import ParkingSpot from '../../../components/molecules/ParkingSpot/ParkingSpot';
import ReservationsTable from '../../../components/molecules/tables/ReservationsTable';
import { Session } from '../../../interface/Session';
import { usePagination } from '../../../hooks/usePagination';

interface QueryParams {
  id: string;
}

const EditGroup = () => {
  const { t } = useTranslation();
  const routerHistory = useHistory();
  const query = useParams<QueryParams>();
  const { search } = useLocation();
  const [isAssignEmployeeModalOpen, setIsAssignEmployeeModalOpen] = useState(false);
  const [isAssignParkingSpotModalOpen, setIsAssignParkingSpotModalOpen] = useState(false);
  const [
    isAssignParkingSpotToEmployeeModalOpen,
    setIsAssignParkingSpotToEmployeeModalOpen,
  ] = useState(false);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [parkingSpots, setParkingSpots] = useState<Spot[]>([]);
  const [parkingSpotsToAssignToEmployee, setParkingSpotsToAssignToEmployee] =
    useState<number>();
  const [sessions, setSessions] = useState<Session[]>([]);
  const { id } = query;

  const params = new URLSearchParams(decodeURIComponent(search));

  const name = params.get('name');
  const type = params.get('type');
  // const days = params.get("days");
  // const spotAssignmentType = params.get("spotAssignmentType");

  const [isLoadingEmployees, setIsLoadingEmployees] = useState(false);
  const [isLoadingSpots, setIsLoadingSpots] = useState(false);
  const [isLoadingSessions, setIsLoadingSessions] = useState(false);
  const [isTogglingSpotInGroup, setIsTogglingSpotInGroup] = useState<{
    [key: number]: boolean;
  }>({});
  const [isTogglingUserInGroup, setIsTogglingUserInGroup] = useState<{
    [key: number]: boolean;
  }>({});

  const [isTogglingEmployeesParkingSpot, setIsTogglingEmployeesParkingSpot] = useState<{
    [key: number]: boolean;
  }>({});

  const {
    perPage: employeesPerPage,
    setPerPage: setEmployeesPerPage,
    page: employeesCurrentPage,
    setPage: setEmployeesCurrentPage,
    total: employeesTotalItems,
    setTotal: setEmployeesTotalItems,
  } = usePagination();

  const {
    perPage: parkingSpotsPerPage,
    setPerPage: setParkingSpotsPerPage,
    page: parkingSpotsCurrentPage,
    setPage: setParkingSpotsCurrentPage,
    total: parkingSpotsTotalItems,
    setTotal: setParkingSpotsTotalItems,
  } = usePagination();

  const {
    perPage: sessionsPerPage,
    setPerPage: setSessionsPerPage,
    page: sessionsCurrentPage,
    setPage: setSessionsCurrentPage,
    total: sessionsTotalItems,
    setTotal: setSessionsTotalItems,
  } = usePagination();

  const loadEmployeesTableData = useCallback(
    async (displayLoader: boolean = true) => {
      try {
        if (displayLoader) {
          setIsLoadingEmployees(true);
        }

        const fetchedGroupEmployees = await employeesApi.fetchParkingGroupEmployees(
          parseInt(id as string)
        );

        setEmployees(fetchedGroupEmployees);
        setEmployeesTotalItems(fetchedGroupEmployees.length);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingEmployees(false);
      }
    },
    [id, setEmployeesTotalItems]
  );

  const loadSessionsTableData = useCallback(
    async (displayLoader: boolean = true) => {
      try {
        if (displayLoader) {
          setIsLoadingSessions(true);
        }

        const sessions = await groupsApi.fetchGroupSessions(
          parseInt(id as string),
          sessionsPerPage,
          sessionsCurrentPage
        );

        setSessions(sessions.items);
        setSessionsTotalItems(sessions.total);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingSessions(false);
      }
    },
    [id, sessionsCurrentPage, sessionsPerPage, setSessionsTotalItems]
  );

  const loadParkingSpotsTable = useCallback(
    async (displayLoader: boolean = true) => {
      try {
        if (displayLoader) {
          setIsLoadingSpots(true);
        }

        const data = await parkingSpotsApi.fetchParkingGroupSpots(
          parseInt(id as string),
          parkingSpotsPerPage,
          parkingSpotsCurrentPage
        );

        setParkingSpots(data.items);

        setParkingSpotsTotalItems(data.total);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingSpots(false);
      }
    },
    [id, parkingSpotsCurrentPage, parkingSpotsPerPage, setParkingSpotsTotalItems]
  );

  const assignEmployeeToGroup = async (employeeId: number) => {
    try {
      setIsTogglingUserInGroup({
        [employeeId]: true,
      });

      await groupsApi.addEmployeeToParkingGroup(employeeId, parseInt(id as string));

      await Promise.all([loadEmployeesTableData(false)]);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsTogglingUserInGroup({
        [employeeId]: false,
      });
    }
  };

  const assignParkingSpotToGroup = async (parkingSpotId: number) => {
    try {
      setIsTogglingSpotInGroup({
        [parkingSpotId]: true,
      });

      await parkingSpotsApi.assignParkingSpotToParkingGroup(
        parseInt(id as string),
        parkingSpotId
      );

      await Promise.all([loadParkingSpotsTable(false)]);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsTogglingSpotInGroup({
        [parkingSpotId]: false,
      });
    }
  };

  const assignParkingSpotToEmployee = async (employeeId: number) => {
    setIsTogglingEmployeesParkingSpot({
      [employeeId]: true,
    });

    try {
      await employeesApi.assignParkingSpotToEmployee(
        parkingSpotsToAssignToEmployee,
        employeeId
      );
    } catch (error) {
      console.log({ error });
    } finally {
      setIsTogglingEmployeesParkingSpot({
        [employeeId]: false,
      });
    }
  };

  useEffect(() => {
    loadSessionsTableData();
  }, [id, loadSessionsTableData, sessionsPerPage, sessionsCurrentPage]);

  useEffect(() => {
    loadEmployeesTableData();
  }, [id, loadEmployeesTableData, employeesPerPage, employeesCurrentPage]);

  useEffect(() => {
    loadParkingSpotsTable();
  }, [id, loadParkingSpotsTable, parkingSpotsPerPage, parkingSpotsCurrentPage]);

  const handleCancelSession = async (session: Session) => {
    await sessionsApi.cancelSession(session.id);

    await loadSessionsTableData(false);
  };

  const handleFinishSession = async (session: Session) => {
    await sessionsApi.finishSession(session.id);

    await loadSessionsTableData(false);
  };

  const usersColumns = [
    {
      key: 'user-email',
      title: t('page:groupDetails.columns.email'),
      dataIndex: 'email',
      render: (row, email) => {
        return <Avatar titleStyle={{ fontSize: 13, margin: 0 }} title={email} />;
      },
    },
    {
      key: 'user-action',
      title: t('page:groupDetails.columns.action'),
      dataIndex: 'id',
      width: '100px',
      render: (row: Employee, employeeId: number) => {
        return (
          <TableActionDropdown>
            <Button
              type='danger'
              size='small'
              inverted
              onClick={async (e) => {
                e.stopPropagation();

                try {
                  setIsTogglingUserInGroup({
                    [employeeId]: true,
                  });

                  await groupsApi.removeEmployeeFromParkingGroup(employeeId);

                  await Promise.all([loadEmployeesTableData(false)]);
                } catch (error) {
                  console.log({ error });
                } finally {
                  setIsTogglingUserInGroup({
                    [employeeId]: false,
                  });
                }
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 10px',
                whiteSpace: 'nowrap',
                border: 'none',
                boxShadow: 'none',
              }}
              loading={isTogglingUserInGroup[employeeId]}
            >
              {t('page:groupDetails.buttons.deleteFromGroup')}
            </Button>
          </TableActionDropdown>
        );
      },
    },
  ];

  const parkingSpotsColumns = [
    {
      key: 'parking-spot-name',
      title: t('page:parkingSpots.columns.name'),
      dataIndex: 'name',
      render: (row, parkingSpot) => {
        if (!parkingSpot) return;

        return <ParkingSpot spot={parkingSpot} level={row.level ?? ''} />;
      },
    },
    {
      key: 'parking-spot-site',
      title: t('page:parkingSpots.columns.site'),
      dataIndex: ['site', 'name'],
    },
    {
      key: 'parking-spot-action',
      title: t('page:parkingSpots.columns.action'),
      width: '100px',
      dataIndex: 'id',
      render: (row: Spot, parkingSpotId: number) => {
        return (
          <TableActionDropdown>
            <Button
              type='danger'
              size='small'
              inverted
              onClick={async (e) => {
                e.stopPropagation();

                try {
                  setIsTogglingSpotInGroup({
                    [parkingSpotId]: true,
                  });

                  await parkingSpotsApi.detachParkingSpotFromParkingGroup(
                    parseInt(id as string),
                    parkingSpotId
                  );

                  await Promise.all([loadParkingSpotsTable(false)]);
                } catch (error) {
                  console.log({ error });
                } finally {
                  setIsTogglingSpotInGroup({
                    [parkingSpotId]: false,
                  });
                }
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 10px',
                width: 140,
                whiteSpace: 'nowrap',
                border: 'none',
                boxShadow: 'none',
              }}
              loading={isTogglingSpotInGroup[parkingSpotId]}
            >
              {t('page:parkingSpots.columns.detach')}
            </Button>
            {/* {type === "static" && (
              <Button
                type="primary"
                size="small"
                inverted
                onClick={async (e) => {
                  e.stopPropagation();

                  setParkingSpotsToAssignToEmployee(parkingSpotId);

                  setIsAssignParkingSpotToEmployeeModalOpen(true);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0 10px",
                  whiteSpace: "nowrap",
                  border: "none",
                  boxShadow: "none",
                }}
              >
                Assign to Employee
              </Button>
            )} */}
          </TableActionDropdown>
        );
      },
    },
  ];

  return (
    <Container>
      <PageHeader title={name} backIcon onBack={() => routerHistory.goBack()} />

      <Tabs
        defaultActiveTabId='parking-spots'
        containerStyle={{
          margin: '20px 0',
        }}
        headerStyle={{
          height: 50,
          backgroundColor: '#fff',
          borderRadius: 6,
          border: '1px solid #f3f3f3',
        }}
      >
        <Tabs.Tab
          id='parking-spots'
          title={t('component:menu.parkingSpots')}
          style={{
            flexDirection: 'column',
          }}
        >
          <PageHeader
            title={t('page:groupDetails.tabs.parkingSpots')}
            descriptions={
              <p className={styles.description}>
                {t('page:groupDetails.tabs.parkingSpotsDescription')}
              </p>
            }
            extra={
              <ButtonIcon
                size='small'
                onClick={() => setIsAssignParkingSpotModalOpen(true)}
                icon={<Plus />}
              >
                {t('page:groupDetails.buttons.assignSpot')}
              </ButtonIcon>
            }
          />
          <Card>
            <Table
              columns={parkingSpotsColumns}
              dataSource={parkingSpots}
              loading={isLoadingSpots}
            />
            <Pagination
              handleChangePage={(page) => setParkingSpotsCurrentPage(page)}
              handleChangePerPage={(perPage) => setParkingSpotsPerPage(perPage)}
              page={parkingSpotsCurrentPage}
              perPage={parkingSpotsPerPage}
              perPageSwitch={false}
              totalItems={parkingSpotsTotalItems}
              itemsFromLabel={t('component:pagination.itemsFrom')}
              itemsLabel={t('component:pagination.items')}
              pageLabel={t('component:pagination.page')}
              perPageLabel={t('component:pagination.perPage')}
              perPageOptions={[5, 10, 15]}
            />
          </Card>
        </Tabs.Tab>

        <Tabs.Tab
          id='employees'
          title={t('component:menu.employees')}
          style={{
            flexDirection: 'column',
          }}
        >
          {/* <div
            style={{
              padding: "20px 20px 12px 20px",
              display: "flex",
              flexDirection: "row",
              margin: "0 0 20px",
            }}
          >
            <div
              style={{
                flex: 1,
              }}
            >
              <Filters
                filters={[]}
                onSearchValueChange={(value) => {
                  console.log(value);
                }}
                searchLabel={t("common:search")}
              />
            </div>
          </div> */}
          <PageHeader
            title={t('page:groupDetails.tabs.employees')}
            descriptions={
              <p className={styles.description}>
                {t('page:groupDetails.tabs.employeesDescription')}
              </p>
            }
            extra={
              <ButtonIcon
                size='small'
                onClick={() => setIsAssignEmployeeModalOpen(true)}
                icon={<Plus />}
              >
                {t('page:groupDetails.buttons.addUser')}
              </ButtonIcon>
            }
          />
          <Card>
            <Table
              columns={usersColumns}
              dataSource={employees}
              loading={isLoadingEmployees}
            />
            {/* <Pagination
              handleChangePage={(page) => setEmployeesCurrentPage(page)}
              handleChangePerPage={(perPage) => setEmployeesPerPage(perPage)}
              page={employeesCurrentPage}
              perPage={employeesPerPage}
              perPageSwitch={false}
              totalItems={employeesTotalItems}
              itemsFromLabel={t('component:pagination.itemsFrom')}
              itemsLabel={t('component:pagination.items')}
              pageLabel={t('component:pagination.page')}
              perPageLabel={t('component:pagination.perPage')}
              perPageOptions={[5, 10, 15]}
            /> */}
          </Card>
        </Tabs.Tab>

        <Tabs.Tab
          id='sessions'
          title={t('component:menu.sessions')}
          style={{
            flexDirection: 'column',
          }}
        >
          <PageHeader
            title={t('page:groupDetails.tabs.sessions')}
            descriptions={
              <p className={styles.description}>
                {t('page:groupDetails.tabs.sessionsDescription')}
              </p>
            }
          />
          <Card>
            <ReservationsTable
              data={sessions}
              loading={isLoadingSessions}
              onCancelSession={handleCancelSession}
              onFinishSession={handleFinishSession}
            />
            <Pagination
              handleChangePage={(page) => setSessionsCurrentPage(page)}
              handleChangePerPage={(perPage) => setSessionsPerPage(perPage)}
              page={sessionsCurrentPage}
              perPage={sessionsPerPage}
              perPageSwitch={false}
              totalItems={sessionsTotalItems}
              itemsFromLabel={t('component:pagination.itemsFrom')}
              itemsLabel={t('component:pagination.items')}
              pageLabel={t('component:pagination.page')}
              perPageLabel={t('component:pagination.perPage')}
              perPageOptions={[5, 10, 15]}
            />
          </Card>
        </Tabs.Tab>

        {/* // TODO: hid till API handles the group details edit */}
        {/* <Tabs.Tab
          id="details"
          title="Details"
          style={{
            flexDirection: "column",
          }}
        >
          <PageHeader
            title={"Parking spots"}
            extra={
              <ButtonIcon
                size="small"
                onClick={() => setIsAssignParkingSpotModalOpen(true)}
                icon={<Plus />}
              >
                Add Parking spot
              </ButtonIcon>
            }
          />
          <Card>
            <div
              style={{
                padding: 20,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <EditGroupForm
                name={name}
                maxSessionMinutes={
                  typeof days === "string" && Math.ceil(parseInt(days) / 1440)
                }
                spotAssignmentType={spotAssignmentType}
                type={type}
                onSubmit={(values) => {}}
              />
            </div>
          </Card>
        </Tabs.Tab> */}
      </Tabs>

      <AssignEmployeeModal
        isOpen={isAssignEmployeeModalOpen}
        onClose={() => setIsAssignEmployeeModalOpen(false)}
        groupId={parseInt(id as string)}
        handleAssignToGroup={assignEmployeeToGroup}
        loadingStates={isTogglingUserInGroup}
      />

      <AssignParkingSpotModal
        isOpen={isAssignParkingSpotModalOpen}
        onClose={() => setIsAssignParkingSpotModalOpen(false)}
        groupId={parseInt(id as string)}
        handleAssignToGroup={assignParkingSpotToGroup}
        loadingStates={isTogglingSpotInGroup}
      />

      <AssignParkingSpotToEmployeeModal
        isOpen={isAssignParkingSpotToEmployeeModalOpen}
        onClose={() => {
          setIsAssignParkingSpotToEmployeeModalOpen(false);

          setParkingSpotsToAssignToEmployee(null);
        }}
        employees={employees}
        loadingStates={isTogglingEmployeesParkingSpot}
        handleAssignParkingSpotToEmployee={assignParkingSpotToEmployee}
      />
    </Container>
  );
};

export default observer(EditGroup);
