import React from 'react';
import { SideMenu } from '@signal-os/ui-kit';

import DashboardIcon from '../../../assets/icons/menu/DashboardIcon';
import ActiveUrlAwareLink from './ActiveUrlAwareLink';
import ParkingIcon from '../../../assets/icons/ParkingIcon';
import AutomationsIcon from '../../../assets/icons/AutomationsIcon';
import VisitorsIcon from '../../../assets/icons/VisitorsIcon';
import { useTranslation } from 'react-i18next';
interface Props {
  isCollapsed: boolean;
}

const GroupedMenu: React.FC<Props> = ({ isCollapsed }) => {
  const { t } = useTranslation();
  return (
    <SideMenu isCollapsed={isCollapsed}>
      <ActiveUrlAwareLink
        to='/organization/sessions'
        icon={<ParkingIcon />}
        isCollapsed={isCollapsed}
      >
        {t('component:menu.sessions')}
      </ActiveUrlAwareLink>
      <ActiveUrlAwareLink
        icon={<AutomationsIcon />}
        isCollapsed={isCollapsed}
        to='/organization/groups'
      >
        {t('component:menu.parkingGroups')}
      </ActiveUrlAwareLink>
      <ActiveUrlAwareLink
        to='/organization/employees'
        icon={<VisitorsIcon />}
        isCollapsed={isCollapsed}
      >
        {t('component:menu.employees')}
      </ActiveUrlAwareLink>
      <ActiveUrlAwareLink
        to='/organization/parkingSpots'
        icon={<DashboardIcon />}
        isCollapsed={isCollapsed}
      >
        {t('component:menu.parkingSpots')}
      </ActiveUrlAwareLink>

      {/* SITE MANAGEMENT

      <SideMenu.SectionTitle isCollapsed={isCollapsed}>
        Site management
      </SideMenu.SectionTitle>
      <ActiveUrlAwareLink
        to="/site/parking-spots"
        icon={<DashboardIcon />}
        isCollapsed={isCollapsed}
      >
        Parking spots
      </ActiveUrlAwareLink>
      <ActiveUrlAwareLink
        to="/site/sessions"
        icon={<DashboardIcon />}
        isCollapsed={isCollapsed}
      >
        Sessions
      </ActiveUrlAwareLink>
      <ActiveUrlAwareLink
        to="/site/organizations"
        icon={<DashboardIcon />}
        isCollapsed={isCollapsed}
      >
        Organizations
      </ActiveUrlAwareLink>
      <ActiveUrlAwareLink
        to="/site/admin"
        icon={<DashboardIcon />}
        isCollapsed={isCollapsed}
      >
        Admin
      </ActiveUrlAwareLink>
    */}
    </SideMenu>
  );
};

export default GroupedMenu;
