import React, { useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Table,
  // Filters,
  Pagination,
} from '@signal-os/ui-kit';
import { useTranslation } from 'react-i18next';
// import useDebounce from "../../../hooks/useDebunce";
import { Spot } from '../../../interface/Spot';
import organizationParkingSpotsApi from '../../../api/repositories/organization/parking-spots';
import { useCallback } from 'react';
import ParkingSpot from '../../molecules/ParkingSpot/ParkingSpot';
import { usePagination } from '../../../hooks/usePagination';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  groupId: number;
  handleAssignToGroup: (parkingSpotId: number) => Promise<void> | void;
  loadingStates: { [key: number]: boolean };
}

const MODAL_HEADER_HEIGHT = '55px';
const MODAL_HEIGHT = '655px';

const AssignParkingSpotModal: React.FC<Props> = ({
  isOpen,
  onClose,
  groupId,
  handleAssignToGroup,
  loadingStates,
}) => {
  const { t } = useTranslation();

  const [parkingSpots, setParkingSpots] = useState<Spot[]>([]);

  const {
    perPage,
    setPerPage,
    page: currentPage,
    setPage: setCurrentPage,
    total: totalItems,
    setTotal: setTotalItems,
  } = usePagination();

  const [isLoading, setIsLoading] = useState(true);

  // const [searchValue, setSearchValue] = useState("");
  // const search = useDebounce(searchValue, 500);

  const fetchOrganizationParkingSpots = useCallback(
    async (displayLoader: boolean = true) => {
      setIsLoading(displayLoader);

      try {
        const data = await organizationParkingSpotsApi.fetchOrganizationParkingSpots(
          perPage,
          currentPage
        );

        setParkingSpots(data.items);

        setTotalItems(data.total);
      } catch (error) {
        console.log({ error });
      } finally {
        setIsLoading(false);
      }
    },
    [perPage, currentPage, setTotalItems]
  );

  useEffect(() => {
    fetchOrganizationParkingSpots(false);
  }, [groupId, isOpen, fetchOrganizationParkingSpots]);

  const columns = [
    {
      key: 'parking-spot-name',
      title: t('page:parkingSpots.columns.name'),
      dataIndex: 'name',
      render: (row, parkingSpot) => {
        if (!parkingSpot) return;

        return <ParkingSpot spot={parkingSpot} level={row.level ?? ''} />;
      },
    },
    {
      key: 'parking-spot-site',
      title: t('page:parkingSpots.columns.site'),
      dataIndex: ['site', 'name'],
    },
    {
      key: 'parking-spot-action',
      title: t('page:parkingSpots.columns.action'),
      width: '300px',
      dataIndex: 'id',
      render: (row: Spot, parkingSpotId: number) => {
        const parkingIsAvailableToAssign = !row.parkingGroupId;
        const isAssignedToCurrentGroup = row.parkingGroupId === groupId;

        return parkingIsAvailableToAssign ? (
          <Button
            type='primary'
            size='small'
            inverted
            onClick={async (e) => {
              e.stopPropagation();

              await handleAssignToGroup(parkingSpotId);

              await fetchOrganizationParkingSpots(false);
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0 10px',
              whiteSpace: 'nowrap',
              border: 'none',
              boxShadow: 'none',
            }}
            loading={loadingStates[parkingSpotId]}
          >
            Assign spot
          </Button>
        ) : isAssignedToCurrentGroup ? (
          <span>In current group</span>
        ) : null;
      },
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      withClose
      style={{
        width: '80%',
        height: MODAL_HEIGHT,
      }}
    >
      <Modal.Header>
        <span style={{ fontSize: 16 }}>
          {t('component:assignParkingSpotModal.title')}
        </span>
      </Modal.Header>
      <Modal.Body
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: `calc(${MODAL_HEIGHT} - ${MODAL_HEADER_HEIGHT})`,
        }}
      >
        {/* <div
          style={{
            marginBottom: 8,
          }}
        >
          <Filters
            filters={[]}
            onSearchValueChange={(value) => setSearchValue(value)}
          />
        </div> */}
        <Table
          columns={columns}
          dataSource={parkingSpots.filter((spot) => spot.parkingGroupId !== groupId)}
          loading={isLoading}
          maxHeight={`calc(${MODAL_HEIGHT} - ${MODAL_HEADER_HEIGHT} - 50px - 40px)`}
        />
        <Pagination
          handleChangePage={(page) => setCurrentPage(page)}
          handleChangePerPage={(perPage) => setPerPage(perPage)}
          page={currentPage}
          perPage={perPage}
          perPageSwitch={false}
          totalItems={totalItems}
          itemsFromLabel={t('component:pagination.itemsFrom')}
          itemsLabel={t('component:pagination.items')}
          pageLabel={t('component:pagination.page')}
          perPageLabel={t('component:pagination.perPage')}
          perPageOptions={[5, 10, 15]}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AssignParkingSpotModal;
