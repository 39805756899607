import { Employee } from '../../../interface/Employee';
import { ParkingSpotResponse } from '../../../interface/responses/ParkingSpotResponse';
import { ParkingGroupResponse } from '../../../interface/responses/ParkingGroupResponse';
import api from '../../index';
import { PaginatedResponse } from '../../../interface/responses/PaginatedResponse';
import { Session } from '../../../interface/Session';

export const fetchParkingGroups = async (perPage: number, page: number) => {
  const { data } = await api.get<PaginatedResponse<ParkingGroupResponse>>(
    `/organization-admin-api/parking-groups`,
    {
      params: {
        perPage,
        page,
      },
    }
  );

  return data;
};

export const addParkingGroup = async (
  name: string,
  type: string,
  spotAssignmentType: string,
  maxSlotsAheadForReservation: number,
  slotResolutionMilliseconds: number,
  employeeMaxActiveSessions: number
): Promise<ParkingGroupResponse> => {
  const { data } = await api.post<ParkingGroupResponse>(
    `/organization-admin-api/parking-groups`,
    {
      name,
      type,
      spotAssignmentType,
      maxSlotsAheadForReservation,
      slotResolutionMilliseconds,
      employeeMaxActiveSessions,
    }
  );

  return data;
};

export const removeParkingGroup = async (
  parkingGroupId: number
): Promise<ParkingGroupResponse> => {
  const { data } = await api.delete<ParkingGroupResponse>(
    `/organization-admin-api/parking-groups/${parkingGroupId}`
  );

  return data;
};

export const addSpotToParkingGroup = async (
  parkingGroupId: number,
  parkingSpotId: number
): Promise<ParkingSpotResponse> => {
  const { data } = await api.post<ParkingSpotResponse>(
    `/organization-admin-api/parking-groups/${parkingGroupId}/parking-spots`,
    {
      parkingSpotId,
    }
  );

  return data;
};

export const removeSpotFromParkingGroup = async (
  parkingGroupId: number,
  parkingSpotId: number
): Promise<ParkingSpotResponse> => {
  const { data } = await api.delete<ParkingSpotResponse>(
    `/organization-admin-api/parking-groups/${parkingGroupId}/parking-spots`,
    {
      params: { parkingSpotId },
    }
  );

  return data;
};

export const addEmployeeToParkingGroup = async (
  employeeId: number,
  parkingGroupId: number
) => {
  const { data } = await api.post<Employee>(
    `/organization-admin-api/employees/${employeeId}/parking-group`,
    {
      parkingGroupId,
    }
  );

  return data;
};

export const removeEmployeeFromParkingGroup = async (employeeId: number) => {
  const { data } = await api.delete<Employee>(
    `/organization-admin-api/employees/${employeeId}/parking-group`
  );

  return data;
};

export const banEmployee = async (employeeId: number) => {
  const { data } = await api.post<Employee>(
    `/organization-admin-api/employees/${employeeId}/ban`
  );

  return data;
};

export const unbanEmployee = async (employeeId: number) => {
  const { data } = await api.delete<Employee>(
    `/organization-admin-api/employees/${employeeId}/ban`
  );

  return data;
};

export const fetchGroupSessions = async (
  parkingGroupId: number,
  perPage: number,
  page: number
) => {
  const { data } = await api.get<PaginatedResponse<Session>>(
    `/organization-admin-api/parking-groups/${parkingGroupId}/sessions`,
    {
      params: {
        perPage,
        page,
      },
    }
  );

  return data;
};

export default {
  fetchParkingGroups,
  addParkingGroup,
  removeParkingGroup,
  addSpotToParkingGroup,
  removeSpotFromParkingGroup,
  addEmployeeToParkingGroup,
  removeEmployeeFromParkingGroup,
  banEmployee,
  unbanEmployee,
  fetchGroupSessions,
};
