import { useCallback, useMemo } from 'react';
import { Card, PageHeader, Pagination } from '@signal-os/ui-kit';
import { useTranslation } from 'react-i18next';

import Container from '../../../components/molecules/Container/Container';
import ReservationsTable from '../../../components/molecules/tables/ReservationsTable';

import sessionsApi from '../../../api/repositories/organization/sessions';
import { Session } from '../../../interface/Session';
import { usePagination } from '../../../hooks/usePagination';
import { useFetch } from '../../../hooks/useFetch';
import { PaginatedResponse } from '../../../interface/responses/PaginatedResponse';

const Reservations = () => {
  const { t } = useTranslation();

  const {
    perPage,
    setPerPage,
    page: currentPage,
    setPage: setCurrentPage,
    total: totalItems,
    setTotal: setTotalItems,
  } = usePagination();

  const fetch = useCallback(
    () => sessionsApi.fetchSessions(currentPage, perPage),
    [currentPage, perPage]
  );

  const config = useMemo(() => {
    return {
      onSuccess: (response: PaginatedResponse<Session>) => {
        setTotalItems(response.total);
      },
    };
  }, [setTotalItems]);

  const { data, isLoading, isError, isSuccess, refetch } = useFetch(fetch, config);

  const handleCancelSession = async (session: Session) => {
    await sessionsApi.cancelSession(session.id);

    await refetch();
  };

  const handleFinishSession = async (session: Session) => {
    await sessionsApi.finishSession(session.id);

    await refetch();
  };

  return (
    <Container>
      <PageHeader title={t('page:sessions.title.sessions')} />
      <Card>
        <ReservationsTable
          data={data?.items ?? []}
          loading={isLoading}
          onCancelSession={handleCancelSession}
          onFinishSession={handleFinishSession}
        />
        <Pagination
          handleChangePage={(page) => setCurrentPage(page)}
          handleChangePerPage={(perPage) => setPerPage(perPage)}
          page={currentPage}
          perPage={perPage}
          perPageSwitch={false}
          totalItems={totalItems}
          itemsFromLabel={t('component:pagination.itemsFrom')}
          itemsLabel={t('component:pagination.items')}
          pageLabel={t('component:pagination.page')}
          perPageLabel={t('component:pagination.perPage')}
          perPageOptions={[5, 10, 15]}
        />
      </Card>
    </Container>
  );
};

export default Reservations;
