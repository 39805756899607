import React from "react";
import { Dropdown } from "@signal-os/ui-kit";
import Action from "../../../../assets/icons/Action";
import styles from "./TableActionDropdown.module.scss";

const TableActionDropdown: React.FC = ({ children }) => {
  return (
    <Dropdown
      overlay={
        <div
          style={{
            padding: "8px",
            backgroundColor: "#fff",
            borderRadius: 6,
            boxShadow:
              "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 20px 50px rgba(0, 0, 0, 0.05)",
            cursor: "default",
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          {children}
        </div>
      }
    >
      <div className={styles.actionButton}>
        <Action />
      </div>
    </Dropdown>
  );
};

export default TableActionDropdown;
