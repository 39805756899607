import React from "react";
import { format as dateFormat } from "date-fns";
import { useTranslation } from "react-i18next";
import pl from "date-fns/locale/pl";
import en from "date-fns/locale/en-US";

interface Props {
  date: Date;
  format: string;
}

const FormatDate: React.FC<Props> = ({ date, format }) => {
  const { i18n } = useTranslation();

  return (
    <>
      {dateFormat(date, format, { locale: i18n.language === "pl" ? pl : en })}
    </>
  );
};

export default FormatDate;
