import store from "../../store/ApplicationStore";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import {} from "@auth0/auth0-react";

const errorHandlers: { [key: number]: (error: any) => void } = {
  401: (error: any) => {
    store.auth.clearToken();

    // TODO: handle
    // if (router.pathname !== "/sign-in") {
    //   router.push(`/sign-in`);
    // }

    throw error;
  },
};

const onResponseInterceptor = (response: AxiosResponse) => {
  return response;
};

const onErrorInterceptor = (error: any) => {
  const errorHandler = errorHandlers[error?.response?.status];

  toast.error(
    error?.response?.data?.message ?? "Something went wrong. Try again",
    {
      progress: undefined,
    }
  );

  if (errorHandler) {
    return errorHandler(error);
  }

  throw error;
};

export { onResponseInterceptor, onErrorInterceptor };
