import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Switch, Route, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { Loader } from '@signal-os/ui-kit';

import '@signal-os/ui-kit/dist/styles/_scrollbar.scss';
import '@signal-os/ui-kit/dist/styles/datepicker.scss';
import './styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';

import './i18n';

import ApplicationStoreProvider from './context/ApplicationStoreContext';
import MainLayout from './layouts/MainLayout/MainLayout';

// Pages
import IndexPage from './pages/index';
import OrganizationEmployeesPage from './pages/organization/employees';
import OrganizationGroupsPage from './pages/organization/groups';
import OrganizationAddGroupPage from './pages/organization/groups/add';
import OrganizationEditGroupPage from './pages/organization/groups/edit';
import OrganizationSessionsPage from './pages/organization/sessions';
import OrganizationParkingSpotsPage from './pages/organization/parkingSpots';
// import SiteAdminPage from "./pages/site/admin";
// import SiteOrganizationsPage from "./pages/site/organizations";
// import SiteParkingSpotsPage from "./pages/site/parkingSpots";
// import SiteAddParkingSpotPage from "./pages/site/parkingSpots/add";
// import SiteSessionsPage from "./pages/site/sessions";

// Auth
import { useAuth0 } from '@auth0/auth0-react';
import api from './api';
import { AxiosRequestConfig } from 'axios';

const App: React.FC = () => {
  const { isLoading, isAuthenticated, error, getAccessTokenSilently, loginWithRedirect } =
    useAuth0();
  const [isAuthorized, setIsAuthorized] = useState(false);

  let location = useLocation();

  const theme = 'dark';

  useEffect(() => {
    if (window.top) {
      window.top.postMessage(
        {
          type: 'signal-os-location-change',
          pathname: location.pathname,
          search: location.search,
          title: document.title,
        },
        '*'
      );
    }
  }, [location]);

  useEffect(() => {
    let interceptorId;
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then((token) => {
          interceptorId = api.interceptors.request.use(
            async (config: AxiosRequestConfig) => {
              // TODO: Set as hardcoded value because of Auth0 is not fully integrated yet (API does not know how to take org_id from JWT token)
              const decodedToken = await jwtDecode<{ org_id: string }>(token);
              console.debug('Decoded token:', decodedToken);
              config['headers']['s-organization-token'] = decodedToken.org_id;
              config['headers']['Authorization'] = 'Bearer ' + token;
              return config;
            }
          );
          setIsAuthorized(true);
          return () => {
            api.interceptors.request.eject(interceptorId);
          };
        })
        .catch((error) => {
          // TODO: handle
        });
    }
  }, [isAuthenticated, isAuthorized, getAccessTokenSilently]);

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    console.error(error);
    return <div className={'announcement ' + theme}>Oops...</div>;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
  } else if (isAuthorized) {
    return (
      <ApplicationStoreProvider>
        <MainLayout>
          <Switch>
            <Route path='/' exact>
              <IndexPage />
            </Route>
            <Route key={'organization/employees'} path='/organization/employees' exact>
              <OrganizationEmployeesPage />
            </Route>

            <Route key={'organization/groups'} path='/organization/groups' exact>
              <OrganizationGroupsPage />
            </Route>
            <Route key={'organization/groups/add'} path='/organization/groups/add' exact>
              <OrganizationAddGroupPage />
            </Route>
            <Route key={'organization/groups/:id'} path='/organization/groups/:id' exact>
              <OrganizationEditGroupPage />
            </Route>
            <Route key={'organization/sessions'} path='/organization/sessions' exact>
              <OrganizationSessionsPage />
            </Route>
            <Route
              key={'organization/parkingSpots'}
              path='/organization/parkingSpots'
              exact
            >
              <OrganizationParkingSpotsPage />
            </Route>

            {/* <Route key={'site/admin'} path="/site/admin" exact>
              <SiteAdminPage />
            </Route>
            <Route key={'site/organizations'} path="/site/organizations" exact>
              <SiteOrganizationsPage />
            </Route>
            <Route key={'site/parking-spots'} path="/site/parking-spots" exact>
              <SiteParkingSpotsPage />
            </Route>
            <Route key={'site/parking-spots/add'} path="/site/parking-spots/add" exact>
              <SiteAddParkingSpotPage />
            </Route>
            <Route key={'site/sessions'} path="/site/sessions" exact>
              <SiteSessionsPage />
            </Route> */}
          </Switch>

          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
          />
        </MainLayout>
      </ApplicationStoreProvider>
    );
  }

  return <div className={'announcement ' + theme}>Not Authorized</div>;
};

export default App;
