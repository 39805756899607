import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  ButtonIcon,
  Card,
  // Filters,
  PageHeader,
  Pagination,
  Table,
  Tag,
} from '@signal-os/ui-kit';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Plus from '../../../assets/icons/Plus';
import Container from '../../../components/molecules/Container/Container';
import groupApi from '../../../api/repositories/organization/groups';
import { ParkingGroupResponse } from '../../../interface/responses/ParkingGroupResponse';
import ConfirmModal from '../../../components/organisms/Modals/ConfirmModal';
import TableActionDropdown from '../../../components/molecules/tables/TableActionDropdown/TableActionDropdown';
import styles from '../../../styles/common.module.scss';
import { usePagination } from '../../../hooks/usePagination';

const Groups = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [groups, setGroups] = useState<ParkingGroupResponse[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [groupToDelete, setGroupToDelete] = useState<ParkingGroupResponse>();

  const {
    perPage,
    setPerPage,
    page: currentPage,
    setPage: setCurrentPage,
    total: totalItems,
    setTotal: setTotalItems,
  } = usePagination();

  const { t } = useTranslation();
  const routerHistory = useHistory();

  const fetchAllGroups = useCallback(async () => {
    try {
      setIsLoading(true);
      const fetchedGroups = await groupApi.fetchParkingGroups(perPage, currentPage);

      setGroups(fetchedGroups.items);

      setTotalItems(fetchedGroups.total);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, perPage, setTotalItems]);

  useEffect(() => {
    fetchAllGroups();
  }, [perPage, currentPage, fetchAllGroups]);

  const parkingSpotType = (type: string) => {
    switch (type) {
      case 'flex':
        return 'info';
      case 'static':
        return 'warning';
      default:
        return 'info';
    }
  };

  const parkingSpotAssignmentType = (type: string) => {
    switch (type) {
      case 'manual':
        return 'danger';
      case 'automatic':
        return 'success';
      default:
        return 'danger';
    }
  };

  const columns = [
    {
      key: 'name',
      title: t('page:groups.columns.name'),
      dataIndex: 'name',
      render: (row, name) => {
        return (
          <span
            className={styles.clickableTableCell}
            onClick={() => {
              routerHistory.push({
                pathname: `/organization/groups/${row.id}`,
                search: `?${encodeURIComponent(
                  `name=${row.name}&type=${row.type}&days=${row.maxMinutesAheadForReservation}&spotAssignmentType=${row.spotAssignmentType}`
                )}`,
              });
            }}
          >
            {name}
          </span>
        );
      },
    },
    {
      key: 'type',
      title: t('page:groups.columns.type'),
      dataIndex: 'type',
      render: (row, type) => {
        return (
          <div
            style={{
              display: 'flex',
            }}
          >
            <Tag
              size='medium'
              type={parkingSpotType(type)}
              solid={false}
              style={{ textTransform: 'capitalize' }}
            >
              {type}
            </Tag>
          </div>
        );
      },
    },
    {
      key: 'spotAssignmentType',
      title: t('page:groups.columns.spotAssignmentType'),
      dataIndex: 'spotAssignmentType',
      render: (row, spotAssignmentType) => {
        return (
          <div
            style={{
              display: 'flex',
            }}
          >
            <Tag
              size='medium'
              type={parkingSpotAssignmentType(spotAssignmentType)}
              solid={false}
              style={{ textTransform: 'capitalize' }}
            >
              {spotAssignmentType}
            </Tag>
          </div>
        );
      },
    },
    {
      key: 'action',
      title: t('page:groups.columns.action'),
      dataIndex: 'action',
      width: '100px',
      render: (row) => {
        return (
          <TableActionDropdown>
            <Button
              style={{
                display: 'flex',
                flex: 1,
                marginBottom: 4,
                padding: '0 10px',
                whiteSpace: 'nowrap',
                border: 'none',
                boxShadow: 'none',
              }}
              type='primary'
              size='small'
              inverted
              onClick={() =>
                routerHistory.push({
                  pathname: `/organization/groups/${row.id}`,
                  search: `?${encodeURIComponent(
                    `name=${row.name}&type=${row.type}&days=${row.maxMinutesAheadForReservation}&spotAssignmentType=${row.spotAssignmentType}`
                  )}`,
                })
              }
            >
              {t('page:groups.buttons.goToDetails')}
            </Button>
            <Button
              style={{
                display: 'flex',
                flex: 1,
                padding: '0 10px',
                whiteSpace: 'nowrap',
                border: 'none',
                boxShadow: 'none',
              }}
              type='danger'
              size='small'
              inverted
              onClick={() => {
                setGroupToDelete(row);

                setIsDeleteModalOpen(true);
              }}
            >
              {t('common:buttons.delete')}
            </Button>
          </TableActionDropdown>
        );
      },
    },
  ];

  return (
    <Container>
      <PageHeader
        title={t('page:groups.title')}
        extra={
          <ButtonIcon
            size='small'
            onClick={() => {
              routerHistory.push('/organization/groups/add');
            }}
            icon={<Plus />}
          >
            {t('page:groups.buttons.addGroup')}
          </ButtonIcon>
        }
      />
      <Card>
        <Table loading={isLoading} columns={columns} dataSource={groups} />
        <Pagination
          handleChangePage={(page) => setCurrentPage(page)}
          handleChangePerPage={(perPage) => setPerPage(perPage)}
          page={currentPage}
          perPage={perPage}
          perPageSwitch={false}
          totalItems={totalItems}
          itemsFromLabel={t('component:pagination.itemsFrom')}
          itemsLabel={t('component:pagination.items')}
          pageLabel={t('component:pagination.page')}
          perPageLabel={t('component:pagination.perPage')}
          perPageOptions={[5, 10, 15]}
        />
      </Card>
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setGroupToDelete(null);
          setIsDeleteModalOpen(false);
        }}
        question={`${t('component:confirmModal.doYouWantToDelete')} ${
          groupToDelete?.name
        } ?`}
        onConfirm={async () => {
          try {
            await groupApi.removeParkingGroup(groupToDelete.id);

            await fetchAllGroups();
          } catch (error) {
            console.log(error);
          }
        }}
      />
    </Container>
  );
};

export default Groups;
