import React, { ReactNode } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { SideMenu } from "@signal-os/ui-kit";

interface Props {
  icon: ReactNode;
  isCollapsed: boolean;
  to: string;
}

const ActiveUrlAwareLink: React.FC<Props> = ({
  icon,
  isCollapsed,
  to,
  children
}) => {
  const match = useRouteMatch({
    path: to,
    exact: false,
  });

  return (
    <SideMenu.Link
      linkComponent={<Link to={to} />}
      icon={icon}
      isActive={!!match}
      isCollapsed={isCollapsed}
    >
      {children}
    </SideMenu.Link>
  );
};

export default ActiveUrlAwareLink;
