import React from 'react';
import { Modal, Button, DragAndDrop } from '@signal-os/ui-kit';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import parkingSpotApi from '../../../api/repositories/organization/parking-spots';
import { Spot } from '../../../interface/Spot';
import { toast } from 'react-toastify';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  parkingSpot: Spot;
  setParkingSpot: any;
}

const AttachedParkingSpotToOrganizationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  parkingSpot,
  setParkingSpot,
}) => {
  const { t } = useTranslation();

  const handleRemoveMap = async () => {
    try {
      const response = await parkingSpotApi.removeMapFromParkingSpot(parkingSpot.id);
      toast.success('Map deleted successfully');
      setParkingSpot(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      withClose
      style={{
        width: '600px',
        height: '700px',
      }}
    >
      <Formik
        initialValues={{
          file: null,
        }}
        onSubmit={async (values, { setErrors }) => {
          try {
            const responseFromAdding = await parkingSpotApi.addMapToParkingSpot(
              parkingSpot.id,
              values.file
            );
            setParkingSpot(responseFromAdding);
            onClose();
            toast.success('Map added successfully');
          } catch (error) {
            setErrors({
              file: t('validation:thereWasAnError'),
            });
          }
        }}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <>
            <Modal.Header headerStyle={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{
                  fontSize: 16,
                }}
              >
                {t('page:parkingSpots.modal.addMapToParkingSpot')}
              </span>
            </Modal.Header>
            <Modal.Body
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {parkingSpot.mapUrl ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <img
                    style={{
                      objectFit: 'contain',
                      width: '100%',
                      height: '100%',
                    }}
                    alt='preview'
                    src={parkingSpot.mapUrl}
                  />
                </div>
              ) : (
                <Field name='file'>
                  {({
                    field: { value, name },
                    form: { setFieldValue },
                    meta: { error, touched },
                  }) => (
                    <DragAndDrop
                      label=''
                      description='Drag photo or click to select'
                      onFile={(file) => {
                        setFieldValue(name, file);
                      }}
                      type='image'
                      error={error && touched ? error : undefined}
                    />
                  )}
                </Field>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  onClose();
                }}
                size='medium'
                type='danger'
                inverted
              >
                {t('common:buttons.cancel')}
              </Button>
              {parkingSpot?.mapUrl ? (
                <Button
                  onClick={() => {
                    handleRemoveMap();
                  }}
                  type='danger'
                  size='medium'
                >
                  {t('page:parkingSpots.modal.removeMap')}
                </Button>
              ) : (
                <Button
                  size='medium'
                  type='primary'
                  onClick={() => handleSubmit()}
                  loading={isSubmitting}
                >
                  {t('common:buttons.submit')}
                </Button>
              )}
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default AttachedParkingSpotToOrganizationModal;
