import axios from "axios";
import { onErrorInterceptor, onResponseInterceptor } from "./interceptors/Auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(onResponseInterceptor, onErrorInterceptor);

export default api;
