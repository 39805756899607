import React from "react";
import {
  Layout
} from "@signal-os/ui-kit";

import "./MainLayout.module.scss";
import Menu from "../../components/organisms/Menu/Menu";

const { Sider, Content } = Layout;

const MainLayout: React.FC = ({ children }) => {
  return (
    <Layout hasSider>
      <Sider collapsible>
        <Menu isCollapsed={false} />
      </Sider>
      <Layout
        style={{
          maxWidth: `calc(100% - 262}px)`,
        }}
      >
        <Content
          style={{
            maxHeight: "100vh",
            overflowY: "auto",
            padding: "24px 64px 64px",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
