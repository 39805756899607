import React, { useState } from 'react';
import { Table, Button, Tag, Avatar } from '@signal-os/ui-kit';
import { useTranslation } from 'react-i18next';
import { Session } from '../../../interface/Session';

import { dateFormats } from '../../../utils/dateFormats';
import FormatDate from '../../atoms/FormatDate/FormatDate';
import TableActionDropdown from './TableActionDropdown/TableActionDropdown';
import ParkingSpot from '../ParkingSpot/ParkingSpot';
import sessionsApi from '../../../api/repositories/organization/sessions';
import ConfirmModal from '../../organisms/Modals/ConfirmModal';

interface Props {
  data: Session[];
  onCancelSession: (session: Session) => Promise<void> | void;
  onFinishSession: (session: Session) => Promise<void> | void;
  loading: boolean;
  showActions?: boolean;
}

const ReservationsTable: React.FC<Props> = ({
  data,
  loading,
  onCancelSession,
  onFinishSession,
  showActions = true,
}) => {
  const { t } = useTranslation();

  const [isCancelSessionLoading, setIsCancelSessionLoading] = useState<{
    [key: number]: boolean;
  }>({});

  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] =
    useState<boolean>(false);
  const [sessionToCancel, setSessionToCancel] = useState(null);

  const getCorrespondingTag = (state: string) => {
    switch (state) {
      case 'started':
        return 'success';
      case 'cancelled':
        return 'warning';
      case 'reserved':
        return 'info';
      default:
        return 'default';
    }
  };

  const getCorrespondingText = (state: string, confirmedAd) => {
    switch (state) {
      case 'started':
        return confirmedAd
          ? t('page:sessions.table.occupied')
          : t('page:sessions.table.started');
      case 'cancelled':
        return t('page:sessions.table.canceled');
      case 'reserved':
        return t('page:sessions.table.reserved');
      default:
        return t('page:sessions.table.finished');
    }
  };

  const checkForCancelSession = (state) => {
    if (state === 'reserved') {
      return false;
    } else if (state === 'started') {
      return false;
    } else return true;
  };

  return (
    <>
      <Table
        columns={[
          {
            key: 'user',
            title: t('component:reservationsTable.columns.user'),
            dataIndex: ['employee', 'email'],
            render: (row, email) => {
              return (
                <Avatar
                  titleStyle={{ fontSize: 13, marginBottom: 0 }}
                  title={email ?? ''}
                />
              );
            },
          },
          {
            key: 'state',
            title: t('component:reservationsTable.columns.state'),
            dataIndex: 'state',
            render: (row, state) => {
              return (
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <Tag size='medium' type={getCorrespondingTag(state)}>
                    {getCorrespondingText(state, row.parkingSpotConfirmedAt)}
                  </Tag>
                </div>
              );
            },
          },
          {
            key: 'parkingSpot',
            title: t('component:reservationsTable.columns.parkingSpot'),
            dataIndex: 'parkingSpot',
            render: (row, parkingSpot) => {
              if (!parkingSpot) return;

              return <ParkingSpot spot={parkingSpot.name} level={parkingSpot.level} />;
            },
          },
          {
            key: 'startDate',
            title: t('component:reservationsTable.columns.date'),
            dataIndex: 'from',
            render: (row, date) => {
              return (
                <FormatDate
                  date={new Date(date)}
                  format={`${dateFormats.day} ${dateFormats.monthShort}`}
                />
              );
            },
          },
          ...(showActions
            ? [
                {
                  key: 'action',
                  title: t('component:reservationsTable.columns.action'),
                  dataIndex: 'id',
                  width: '100px',
                  render: (row, id) => {
                    return (
                      <TableActionDropdown>
                        <Button
                          type={checkForCancelSession(row.state) ? 'primary' : 'danger'}
                          size='small'
                          inverted
                          onClick={async (e) => {
                            e.stopPropagation();

                            setIsCancelSessionLoading({
                              [id]: true,
                            });

                            try {
                              if (row.state === 'started') {
                                setSessionToCancel(row);
                                setIsConfirmCancelModalOpen(true);
                              } else await onCancelSession(row);
                            } catch (error) {
                              console.log({ error });
                            } finally {
                              setIsCancelSessionLoading({
                                [id]: false,
                              });
                            }
                          }}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '0 10px',
                            whiteSpace: 'nowrap',
                            border: 'none',
                            boxShadow: 'none',
                          }}
                          loading={isCancelSessionLoading[id]}
                          disabled={checkForCancelSession(row.state)}
                        >
                          {t('common:buttons.cancel')}
                        </Button>
                      </TableActionDropdown>
                    );
                  },
                },
              ]
            : []),
        ]}
        dataSource={data}
        loading={loading}
      />
      <ConfirmModal
        isOpen={isConfirmCancelModalOpen}
        onClose={() => {
          setIsConfirmCancelModalOpen(false);
          setSessionToCancel(null);
        }}
        question={t('component:reservationsTable.cancelModal.question')}
        onConfirm={async () => {
          try {
            await onFinishSession(sessionToCancel);
          } catch (error) {
            console.log(error);
          } finally {
            setSessionToCancel(null);
          }
        }}
      />
    </>
  );
};

export default ReservationsTable;
