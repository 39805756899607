import { Redirect } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <Redirect to="/organization/sessions" />
    </div>
  );
};

export default Home;
