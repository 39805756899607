import React, { useState } from 'react';
import { Modal, Button } from '@signal-os/ui-kit';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void | Promise<void>;
  question: string;
}

const ConfirmModal: React.FC<Props> = ({ isOpen, onClose, onConfirm, question }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);

    await onConfirm();

    setIsLoading(false);

    onClose();
  };

  return (
    <Modal withClose onClose={onClose} isOpen={isOpen} style={{ height: '300px' }}>
      <Modal.Header>
        <span
          style={{
            display: 'block',
            fontSize: '16px',
            paddingBottom: '8px',
          }}
        >
          {t('component:confirmModal.areYouSure')}
        </span>
      </Modal.Header>
      <Modal.Body
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {question}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} size='medium' type='primary'>
          {t('common:buttons.no')}
        </Button>
        <Button
          size='medium'
          type='danger'
          onClick={() => handleConfirm()}
          loading={isLoading}
        >
          {t('common:buttons.yes')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
